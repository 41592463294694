import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import Logo from "src/component/Logo";
const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflowX: "auto",
    background: theme.palette.background.dark1,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      overflow: "unset",
    },
  },
  headbox: {
    margin: "0",
    padding: "0",
    background: theme.palette.background.dark1,
    height: "100vh",
  },
  imgbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#624D19",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& img": {
      maxWidth: "100%",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.headbox}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Container maxWidth="sm">
              <Box className={classes.content}>
                <Box style={{ width: "100%" }}>{children}</Box>
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box className={classes.imgbox}>
              <img className={classes.images} src="images/guide.png" alt="dd" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
