/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import { FaTachometerAlt, FaClipboardCheck } from "react-icons/fa";
import { HiDocumentText } from "react-icons/hi";
import { RiAdvertisementFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { MdLogout } from "react-icons/md";
import { GiToken, GiTrade } from "react-icons/gi";
import NavItem from "./NavItem";
import PeopleIcon from "@material-ui/icons/People";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { HiOutlineIdentification } from "react-icons/hi";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AiFillBank } from "react-icons/ai";
import { VscFeedback } from "react-icons/vsc";
import { FaWallet } from "react-icons/fa";
import { RiExchangeDollarLine } from "react-icons/ri";
import { MdLocalActivity } from "react-icons/md";
import { FaTicketAlt } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { Link } from "react-router-dom";
const sections = [
  {
    items: [
      {
        title: "Home",
        icon: FaTachometerAlt,
        href: "/",
      },
      {
        title: "Dashboard",
        icon: DashboardIcon,
        href: "/dashboard",
      },
      {
        title: "Staff Management",
        icon: FaClipboardCheck,
        href: "/staff-management",
      },
      {
        title: "Hot Wallet Management",
        icon: FaWallet,
        href: "/hot-wallet",
      },
      {
        title: "Kyc Management",
        icon: HiOutlineIdentification,
        href: "/kyc",
      },
      {
        title: "Bank Management",
        icon: AiFillBank,
        href: "/bank-list",
      },
      {
        title: "User-Management",
        icon: PeopleIcon,
        href: "/user-management",
      },
      {
        title: "Fee MANAGEMENT",
        icon: VscFeedback,
        href: "/fee-management",
      },
      {
        title: "Wallet Management",
        icon: AccountBalanceWalletIcon,
        href: "/wallet-management",
      },
      {
        title: "Staking Management",
        icon: RiExchangeDollarLine,
        href: "/staking-management",
      },
      {
        title: "Logs Management",
        icon: MdLocalActivity,
        href: "/logs-management",
      },
      {
        title: "Announcement",
        icon: RiAdvertisementFill,
        href: "/announcement",
      },
      {
        title: "Ticket Management",
        icon: FaTicketAlt,
        href: "/ticket-management",
      },
      {
        title: "Blogs Management",
        icon: RiAdvertisementFill,
        href: "/blogs-management",
      },
      // {
      //   title: "Static Management",
      //   icon: HiDocumentText,
      //   href: "/static-management",
      // },
      {
        title: "Settings",
        icon: AiFillSetting,
        href: "/settings",
      },
    ],
  },
];
const sectionsBelow = [
  {
    items: [
      {
        title: "Logout",
        icon: ExitToAppIcon,
        href: "/terms-and-condition",
      },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "#242538",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    // background: "#242538",
    // boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    // background: "#0B0C21",
    background: "#111010",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    justifyContent: "space-between",
    paddingLeft: 18,
    borderRadius: 0,
    width: "60px",
    textAlign: "center",
    color: "#9A9A9A",
    fontWeight: "400",
  },
  adminlogo: {
    marginTop: "20px",
    "& .boxImage": {
      position: "relative",
      display: "inline-block",
      width: "100px",
      display: " flex",
      justifyContent: "center",
      margin: "0 auto",
      borderRadius: "100%",
      backgroundColor: theme.palette.background.darkgrey,

      "& img": {
        width: "100%",
        border: "1px solid #fff",
        maxWidth: "100px",
        minWidth: "100px",
        borderRadius: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // margin: "40px auto",
        [theme.breakpoints.down("xs")]: {
          margin: "26px auto",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "200px",
      },
    },
    "& h5": {
      color: "#fff",
      paddingTop: "10px",
      textAlign: "center",
      border: "1px solid rgb(180 193 206 / 20%)",
      width: "100%",
      maxWidth: "196px",
      margin: "0 auto",
      paddingBottom: "7px",
      paddingTop: "7px",
    },
  },
  btnname: {
    paddingRight: "20px",
    "& button": {
      minWidth: "150px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden mdDown>
        <Box padding={0} className={classes.logoicon}>
          <Logo
            width="180"
            style={{
              width: "110px",

              cursor: "pointer",
            }}
          />
        </Box>
      </Hidden>
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box className={classes.adminlogo}>
          <Box className="boxImage">
            <img src="images/profile.png" width="100%" />
          </Box>
          <Box mt={1}>
            <Link to="/my-profile" style={{ textDecoration: "none" }}>
              <Typography variant="h5">Prakash Singh</Typography>
            </Link>
          </Box>
        </Box>
        <Box pt={2}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Box pb={2}>
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {section.items.map((itemList, i) => {
                return (
                  <Box align="left">
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={
                        <IoLogOutOutline
                          style={{
                            fontSize: "25px",
                            color: "9A9A9A",
                            borderLeft: "solid 8px transparent",
                            marginRight: "3px",
                          }}
                        />
                      }
                      key={i}
                      className={classes.logoutbutton}
                      style={{}}
                      onClick={handleClickOpen}
                    >
                      {itemList.title}
                    </Button>
                    <Dialog
                      open={open}
                      fullWidth
                      maxWidth="sm"
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        style={{ textAlign: "center" }}
                      >
                        {"Delete"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          align="center"
                          style={{
                            color: "#000",
                            fontFamily: "'Poppins', sans-serif",
                          }}
                        >
                          Are you sure you want to Logout ?
                        </DialogContentText>
                        <Box
                          display="flex"
                          justifyContent="center"
                          pt={3}
                          pb={3}
                        >
                          <Box className={classes.btnname}>
                            <Link to="/" style={{ textDecoration: "none" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClose}
                              >
                                Yes
                              </Button>
                            </Link>
                          </Box>
                          <Box className={classes.btnname}>
                            <Button variant="contained" color="secondary">
                              No
                            </Button>
                          </Box>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Box>
                );
              })}

              {/* {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })} */}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
