import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn")),
  },

  {
    exact: true,
    path: "/dashboard",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardMain/index")
    ),
  },

  {
    exact: true,
    path: "/staff-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaffManagement/StaffUserlist")
    ),
  },
  {
    exact: true,
    path: "/staff-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaffManagement/StaffViewDetails")
    ),
  },
  {
    exact: true,
    path: "/staffedit-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaffManagement/StaffEditProfile")
    ),
  },
  {
    exact: true,
    path: "/hot-wallet",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagement/HotWalletList")
    ),
  },
  {
    exact: true,
    path: "/hotwallet-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagement/TransactionTab")
    ),
  },
  {
    exact: true,
    path: "/coldwallet-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/HotWalletManagement/ColdwalletTransaction"
      )
    ),
  },
  {
    exact: true,
    path: "/bank-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BankManagement/BankList")
    ),
  },
  {
    exact: true,
    path: "/fee-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FeeManagement/Feemanagements")
    ),
  },
  {
    exact: true,
    path: "/fee-trading",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FeeManagement/TradingFees")
    ),
  },
  {
    exact: true,
    path: "/minimum-trading",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FeeManagement/MinimumTrading")
    ),
  },
  {
    exact: true,
    path: "/withdrawl-fee",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagement/WithdrawlFee")
    ),
  },
  {
    exact: true,
    path: "/wallet-transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/HotWalletManagement/WalletTransaction")
    ),
  },
  {
    exact: true,
    path: "/wallet-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WalletManagement/index")
    ),
  },
  {
    exact: true,
    path: "/staking-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/index")
    ),
  },

  {
    exact: true,
    path: "/fd-view",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/FdView")
    ),
  },
  {
    exact: true,
    path: "/fd-edit",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StakingManagement/FdEdit")
    ),
  },
  {
    exact: true,
    path: "/logs-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/LogsManagement/index")
    ),
  },
  {
    exact: true,
    path: "/wallet-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/WalletDetails")
    ),
  },
  {
    exact: true,
    path: "/trading-details",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/TradingDetails")
    ),
  },
  {
    exact: true,
    path: "/user-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/index")
    ),
  },
  {
    exact: true,
    path: "/user-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/UserManagement/UserList")
    ),
  },
  {
    exact: true,
    path: "/kyc",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC/KycList")),
  },
  {
    exact: true,
    path: "/kyc-details",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/KYC/KycViewDetails")
    ),
  },

  {
    exact: true,
    path: "/static-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Static")
    ),
  },
  {
    exact: true,
    path: "/announcement",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/Index")
    ),
  },

  {
    exact: true,
    path: "/view-add",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/ViewAdd")
    ),
  },
  {
    exact: true,
    path: "/add-add",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/AddAdds")
    ),
  },
  {
    exact: true,
    path: "/edit-add",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Announcement/EditAdd")
    ),
  },
  {
    exact: true,
    path: "/ticket-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TicketManagement/index")
    ),
  },
  {
    exact: true,
    path: "/view-ticket",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TicketManagement/ViewTicket")
    ),
  },
  {
    exact: true,
    path: "/ticket-reply",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/TicketManagement/TicketReply")
    ),
  },
  {
    exact: true,
    path: "/blogs-management",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BlogsManagement/index")
    ),
  },
  {
    exact: true,
    path: "/edit-blogs",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BlogsManagement/EditBlogs")
    ),
  },
  {
    exact: true,
    path: "/settings",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Settings/index")),
  },
  {
    exact: true,
    path: "/view-withdraw",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/ViewWithdraw")
    ),
  },
  {
    exact: true,
    path: "/withdraw-fee",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/WithdrawFee")
    ),
  },
  {
    exact: true,
    path: "/withdraw-limit",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/WithdrawLimit")
    ),
  },
  {
    exact: true,
    path: "/my-profile",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Settings/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/EditProfile")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Settings/ChangePassword")
    ),
  },
  {
    exact: true,
    path: "/social-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinks")
    ),
  },

  {
    exact: true,
    path: "/edit-links",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/SocialLinksEdit")
    ),
  },

  {
    exact: true,
    path: "/view-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/About")
    ),
  },

  {
    exact: true,
    path: "/view-announcements",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/Announcements")
    ),
  },

  {
    exact: true,
    path: "/edit-announcements",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AnnouncementsEdit")
    ),
  },

  {
    exact: true,
    path: "/edit-content",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/AboutEdit")
    ),
  },

  {
    exact: true,
    path: "/view-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },

  {
    exact: true,
    path: "/edit-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsConditionEdit")
    ),
  },

  {
    exact: true,
    path: "/policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicy")
    ),
  },

  {
    exact: true,
    path: "/edit-policy",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/PrivacyPolicyEdit")
    ),
  },

  {
    exact: true,
    path: "/view-terms",
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/StaticManagement/TermsCondition")
    ),
  },

  {
    exact: true,
    path: "/add-token",
    // guard:true,
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KYC")),
  },

  {
    exact: true,
    path: "/terms-condition",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/TermsAndConditions")),
  },
  {
    exact: true,
    path: "/terms-services",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/TermsAndConditions/TermsServices")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
