import React, {
  Suspense,
  Fragment,
  useState,
  useContext,
  useEffect,
} from "react";
import { Router, Switch, Route } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { routes } from "src/routes";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switche from "@material-ui/core/Switch";
import { createBrowserHistory } from "history";
import AuthContext from "src/context/Auth";
import PageLoading from "src/component/PageLoading";
import AuthGuard from "src/component/AuthGuard";
// import { ThemeProvider } from "@material-ui/core";
//  import { createTheme } from "src/theme";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Container,
  Link,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import typography from "src/theme/typography";
import _ from "lodash";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const history = createBrowserHistory();

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  toggleButtonShell: {
    // border: '1px solid #000',
    borderRadius: "32px",
    width: "84px",
    // backgroundColor: '#000',
    height: "34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "3px 0px",
  },
  toggleButton: {
    border: "1px solid #000",
    borderRadius: "100%",
    display: "flex",
    padding: "6px",
    cursor: "pointer",
  },
}));

export const light = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#1e1f2e",
      dark: "#1e1f2e",
      // dark1: "#161515",
      // dark1: "#070713",
      // dark1: "#05031A",
      dark1: "#000",
      paper: "#ccc",
      footercolor: "#4c4949",
      lightblack: "#282727",
      subfootercolor: "#221f1f",
      circleBoxColor: "#424040",
      addBox: "#424040",
      blueBox: "#424040",
    },

    primary: {
      main: "#fff",
      dark: "#fff",
      light: "#f5f5f5",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
      black: "#fff",
    },
    common: {
      black: "#222222",
    },
  },
};
const baseOptions = {
  typography,
  overrides: {
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "#545252",
          color: "#fff",
        },
      },
    },
    MuiInput: {
      underline: {
        "&::after": {
          display: "none",
        },
      },
    },
    MuiAppBar: {
      positionFixed: {
        top: "0",
        left: "auto",
        right: "0",
        position: "fixed",
        // background: "#242538",
        // background: "#0B0C21",
        background: "#111010",
      },
    },
    MuiTableCell: {
      root: {
        display: "table-cell",
        fontSize: "13px",
        textAlign: "center",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "300",
        lineHeight: "1.43",
        paddingTop: "7px",
        borderBottom: "1px solid rgba(120,130,140,.13)",
        paddingBottom: "7px",
        verticalAlign: "inherit",
      },
    },

    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
      notchedOutline: {
        borderColor: "none",
      },
      inputMarginDense: {
        paddingTop: "24.5px",
        paddingBottom: "24.5px",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "none",
      },
      outlined: {
        padding: "20px",
        width: "100%",
        border: "none",
      },
      rounded: {
        borderRadius: "7px",
      },
      elevation1: {
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiList: {
      padding: {
        paddingLeft: "8px",
        backgroundColor: "#151313",
        color: "#fff",
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiDropzoneArea: {
      text: {
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 200,
      },
      root: {
        width: "100%",
        maxWidth: "50%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      icon: {
        backgroundColor: "#f5f7fa",
        color: "#5a86ff",
        padding: "20px",
      },
    },

    MuiFormControl: {
      root: {
        border: "0",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
        width: "100%",
      },
    },

    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#202020",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#8F8F8F",
        fontSize: "16px",
        fontWeight: "400",
        margin: "0 15px",
      },
      toolbarBtnSelected: {
        margin: "0px 5px",
        marginTop: "7px",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#EAB73B",
        color: "#fff",
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        width: "100%",
        maxWidth: "326px !important",
      },
    },

    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: "#fff",
        color: "#170C41",
      },
      switchHeader: {
        backgroundColor: "#111010",
        color: "#fff",
        marginTop: "0px",
        marginBottom: "13px",
      },
      dayLabel: {
        color: "#fff",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: "#202020",
      },
      containerLandscape: {
        flexDirection: "column",
      },
      container: {
        backgroundColor: "#111010",
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: "#111010",
      },
    },
    MuiTableCell: {
      head: {
        // color: '#52565c',
        fontWeight: "500",
        lineHeight: "1.5rem",
      },
      root: {
        fontSize: "13px",
        color: "#595959",
        paddingBottom: "7px",
        paddingTop: "7px",
        borderBottom: "1px solid rgba(120,130,140,.13)",
      },
      stickyHeader: {
        backgroundColor: "none",
        color: "#9f9f9f",
        fontWeight: "300",
        fontSize: "14px",
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: "100%",
        maxWidth: "100%",
      },
      // .MuiDialog-paperWidthSm
      paperScrollPaper: {
        width: "100%",

        display: "flex",
        maxWidth: "100%",
        maxHeight: "calc(100% - 64px)",
        flexDirection: "column",
      },

      paper: {
        overflowY: "unset",
        backgroundColor: "#fff",
      },
      paperWidthSm: {
        maxWidth: "500px !important",
      },
    },
    MuiSelect: {
      outlined: {
        "&.MuiSelect-outlined": {
          fontSize: "14px",
          color: "#6D6D6D !important",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "19px",
        color: "#666666",
      },
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px !important",

        marginRight: "0px !important",
      },
    },
    MuiButton: {
      root: {
        color: "#300760",
        minWidth: "0px",
        //padding: '6px 60px',
        borderRadius: "none",
      },
      text: {
        padding: "3px 3px ",
      },
      label: {
        fontSize: "14px",
        fontWeight: "400",
      },
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        backgroundColor: "#1C1C1C",
        color: "#808080",
        fontSize: "14px",

        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "#EAB73B",
          color: "#000",
        },
      },

      containedPrimary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "4px",
        backgroundColor: "#EAB73B",
        color: "#000",
        fontSize: "14px",

        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "#EAB73B",
          color: "#000",
        },
      },
      outlinedPrimary: {
        color: "rgb(90 134 255)",
        fontWeight: 600,
        height: "50px",
        minWidth: "100px",
        border: "1px solid rgb(90 134 255)",
        "&:hover": {
          backgroundColor: "rgb(90 134 255)",
          border: "1px solid rgb(90 134 255)",
          color: "#fff",
        },
      },
      outlinedSecondary: {
        color: "#00e0b0",
        border: "1px solid rgba(0, 224, 176, 0.5)",
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      body1: {
        fontSize: "15px",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "300",
        lineHeight: "1.5",
      },
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};
export const dark = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "dark",
    action: {
      primary: "#222222",
    },
    background: {
      default: "#fff",
      dark: "#fff",
      dark1: "#fff",
      paper: "#f5f5f5",
      lightblack: "#f5f7fa",
      footercolor: "#242538",
      subfootercolor: "#1e1f2e",
      circleBoxColor: "#fff9fa",
      riskybox: "#7a7a7a",
      addBox: "#eaf2f7",
      blueBox: "#dbf1ff",
    },
    primary: {
      main: "#222222",
      dark: "#222222",
      light: "#222222",
    },

    secondary: {
      main: "#222222",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#000",
      secondary: "#fff",
      black: "#000",
    },
    common: {
      black: "#222222",
    },
  },
};

function App() {
  // const theme = createTheme();
  // const [themeApp, setThemeApp] = useState(true)
  const [theme, setTheme] = useState(true);
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const icon = !theme ? (
    <Brightness7Icon style={{ color: "#ffc107" }} />
  ) : (
    <Brightness3Icon />
  );
  // useEffect(() => {
  //   console.log('auth?.theme----', auth?.theme)
  //   setThemeApp(auth?.theme)
  // }, [auth?.theme])

  const appliedTheme = createMuiTheme(
    _.merge({}, baseOptions, theme ? light : dark)
  );

  return (
    <div className="App">
      <ToastContainer />
      <ThemeProvider theme={appliedTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AuthContext>
            <Router history={history}>
              <RenderRoutes data={routes} />
              {/* <IconButton
                className="buttonThemeHange"
                edge="end"
                color="inherit"
                aria-label="mode"
                onClick={() => setTheme(!theme)}
              >
                {icon}
              </IconButton> */}
              {/* <Box
                className={`${classes.toggleButtonShell} buttonThemeHange`}
                style={
                  !theme
                    ? { backgroundColor: "#fff" }
                    : { backgroundColor: "#4c4949", border: "1px solid #000" }
                }
              >
                <IconButton
              
                  style={
                    theme
                      ? { color: "#000", padding: "8px" }
                      : { background: "#242538", padding: "8px" }
                  }
                >
                  <Brightness7Icon
                    style={
                      theme ? { color: "#000" } : { color: "rgb(244 144 24)" }
                    }
                    onClick={() => setTheme(false)}
                  />
                </IconButton>
                <IconButton
              
                  style={
                    !theme
                      ? { color: "#000", padding: "8px" }
                      : { background: "#000", padding: "8px" }
                  }
                >
                  <Brightness3Icon
                    onClick={() => setTheme(true)}
                    style={
                      !theme
                        ? { color: "#000", transitionDuration: "4s" }
                        : {
                            color: "#ffc107",
                            transform: "rotate(152deg)",
                            transitionDuration: "4s",
                          }
                    }
                  />
                </IconButton>
              </Box> */}
            </Router>
          </AuthContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

function RenderRoutes(props) {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        {props.data.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              exact={route.exact}
              key={i}
              path={route.path}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      <RenderRoutes data={route.routes} />
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}
