// let url = "http://172.16.1.172:1884";
let url = "https://java-cryptostaking.mobiloitte.com";

const user = `${url}/account`;
const auth = `${url}/auth`;
const apiConfig = {
  userLogin: `${auth}`,
  //USER
};

export default apiConfig;
